.font-button {
	width: 38px;
	height: 38px;
	flex: none;
	order: 1;
	flex-grow: 0;
	background: #ffffff;
	box-shadow: 0px 0.5px 4px rgba(0, 0, 0, 0.12);
	font-size: 14px;
	line-height: 18px;
	display: flex;
	align-items: center;
	text-align: center;
	border-radius: 100px;
	text-align: center;
	justify-content: center;
	font-weight: 500;
	color: #000000;
	transition: all 230ms ease-in-out;
	border: white 1px solid;
}

.font-button.selected {
	background-color: #21187f;
	color: #fff;
}
.font-button:hover {
	box-shadow: 0px 0.5px 10px rgba(0, 0, 0, 0.2);
	/* border-color: #F1F1FE; */
	--tw-scale-x: 1.35;
	--tw-scale-y: 1.35;
	transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.font-button.selected:hover {
	box-shadow: none;
	cursor: default;
	transform: none;
}

.color-button-ring {
	padding: 2px;
	border: 2px solid transparent;
	border-radius: 100px;
	transition: all 230ms ease-in-out;
}
.color-button-ring.selected {
	border-color: #21187f;
}

.color-button {
	width: 22px;
	height: 22px;
	border-radius: 100px;
	border: 1px solid black;
	transition: all ease-in-out 230ms;
}
.color-button:hover {
	box-shadow: 0px 0.5px 10px rgba(0, 0, 0, 0.2);
	--tw-scale-x: 1.35;
	--tw-scale-y: 1.35;
	transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.color-button-ring.selected > .color-button:hover {
	box-shadow: none;
	transform: none;
}

.slider {
	-webkit-appearance: none;
	width: 100%;
	height: 4px;
	background: rgba(229, 221, 246, 1);
	outline: none;
	-webkit-transition: 0.2s;
	transition: opacity 0.2s;
	border-radius: 100px;
}

.slider::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 28px;
	height: 28px;
	background: #ffffff;
	cursor: pointer;
	box-shadow: 0px 6px 13px 0px rgba(0, 0, 0, 0.12);
	box-shadow: 0px 0.5px 4px 0px rgba(0, 0, 0, 0.12);
	border-radius: 100px;
}

.slider:hover::-webkit-slider-thumb {
	box-shadow: 0px 0.5px 10px rgba(0, 0, 0, 0.2);
}

.slider::-moz-range-thumb {
	border-radius: 100px;
	width: 28px;
	height: 28px;
	background: #ffffff;
	cursor: pointer;
	box-shadow: 0px 6px 13px 0px rgba(0, 0, 0, 0.12);

	box-shadow: 0px 0.5px 4px 0px rgba(0, 0, 0, 0.12);
}
