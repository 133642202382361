.banner-container {
	overflow: hidden; /* Sakrije tekst koji izlazi izvan kontejnera */
	white-space: nowrap; /* Sprečava prelom teksta na novu liniju */
	box-sizing: border-box;
}

.scrolling-banner {
	display: inline-flex;
	animation: scrollLeft 40s linear infinite;
	gap: 6px;
}
@keyframes scrollLeft {
	0% {
		transform: translateX(0); /* Početak animacije sa desne strane */
	}
	100% {
		transform: translateX(-50%);
	}
}
