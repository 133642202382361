@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
body {
	margin: 0;
	font-family: 'Noto Sans', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: 'Manrope', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.md-custom p {
	white-space: pre-wrap;
}
.contextContainer {
	z-index: 1;
	width: 100%;
	height: 500px;
	background: #673ab7;
}
.rightClick {
	z-index: 12;
	position: fixed;
	background: rgb(240, 200, 1);
}
.menuElement {
	color: #222222;
	cursor: pointer;
	padding: 17px 36px;
}
.menuElement:hover {
	color: #fff;
	background: #009688;
}
.custom-toast::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.2); /* Semi-transparent black overlay */
	backdrop-filter: blur(2px); /* Blur effect */
	z-index: -1; /* Position behind the content */
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.gsi-material-button {
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	-webkit-appearance: none;
	background-color: #131314;
	background-image: none;
	border: 1px solid #747775;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	color: #e3e3e3;
	cursor: pointer;
	font-family: 'Roboto', arial, sans-serif;
	font-size: 14px;
	height: 40px;
	letter-spacing: 0.25px;
	outline: none;
	overflow: hidden;
	padding: 0 12px;
	position: relative;
	text-align: center;
	-webkit-transition:
		background-color 0.218s,
		border-color 0.218s,
		box-shadow 0.218s;
	transition:
		background-color 0.218s,
		border-color 0.218s,
		box-shadow 0.218s;
	vertical-align: middle;
	white-space: nowrap;
	width: auto;
	max-width: 400px;
	min-width: min-content;
	border-color: #8e918f;
}

.gsi-material-button .gsi-material-button-icon {
	height: 20px;
	margin-right: 12px;
	min-width: 20px;
	width: 20px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
	-webkit-align-items: center;
	align-items: center;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: nowrap;
	flex-wrap: nowrap;
	height: 100%;
	justify-content: space-between;
	position: relative;
	width: 100%;
}

.gsi-material-button .gsi-material-button-contents {
	-webkit-flex-grow: 1;
	flex-grow: 1;
	font-family: 'Roboto', arial, sans-serif;
	font-weight: 500;
	overflow: hidden;
	text-overflow: ellipsis;
	vertical-align: top;
}

.gsi-material-button .gsi-material-button-state {
	-webkit-transition: opacity 0.218s;
	transition: opacity 0.218s;
	bottom: 0;
	left: 0;
	opacity: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.gsi-material-button:disabled {
	cursor: default;
	background-color: #13131461;
	border-color: #8e918f1f;
}

.gsi-material-button:disabled .gsi-material-button-state {
	background-color: #e3e3e31f;
}

.gsi-material-button:disabled .gsi-material-button-contents {
	opacity: 38%;
}

.gsi-material-button:disabled .gsi-material-button-icon {
	opacity: 38%;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state,
.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
	background-color: white;
	opacity: 12%;
}

.gsi-material-button:not(:disabled):hover {
	-webkit-box-shadow:
		0 1px 2px 0 rgba(60, 64, 67, 0.3),
		0 1px 3px 1px rgba(60, 64, 67, 0.15);
	box-shadow:
		0 1px 2px 0 rgba(60, 64, 67, 0.3),
		0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

.gsi-material-button:not(:disabled):hover .gsi-material-button-state {
	background-color: white;
	opacity: 8%;
}
.no-scroll {
	-ms-overflow-style: none; /* Internet Explorer 10+ */
	scrollbar-width: none; /* Firefox */
}
.no-scroll::-webkit-scrollbar {
	display: none; /* Safari and Chrome */
}
