.default-config-tab {
	height: 48px;
	padding: 0 24px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-items: center;
	gap: 6px;
	border-radius: 12px 12px 0 0;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	transition: all ease-in-out 230ms;
}

.default-config-tab.selected {
	background-color: #21187f;
	color: white;
}
